import React, { useEffect, useState } from 'react';
import { connect  } from "react-redux";
import { IStore } from "../../reducers/IStore";
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import fetchTilesCounters from '../../actionCreators/fetchTilesCounters';
import styles from './HomeMessages.module.scss';
import { Card } from '../../core/components/Card/Card';
import { CardHeader } from '../../core/components/Card/components/CardHeader';
import {
  CardContent,
  CardContentVariants,
} from '../../core/components/Card/components/CardContent';
import TranslationService from '../../core/services/TranslationService';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import ConversationList from './components/ConversationList/ConversationList';
import ConversationMsgs from './components/ConversationMsgs/ConversationMsgs';
import { IMessageFolderType } from './enums/IMessageFolderType';
import { IConversationsOrderByType } from './enums/IConversationsOrderByType';
import { emptyMessageState } from '../Messages/types/IMessagesState';
import { IOrderByType } from '../../core/Enums/IOrderByType';
import {
  getConversations,
  getConversation,
  sendNewMessage,
} from './HomeMessagesService';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import {
  ConversationType,
  ConversationDetailsType,
} from './types/ConversationType';
import Loader from '../../core/components/Loading/Loader';
import { INewMessageRequest } from './types/INewMessageRequest';
import { INewMessage } from './types/INewMessage';
import { Status } from '../../core/api/Enums/Status';
import classNames from 'classnames';
import InfoMessageService from '../../core/services/InfoMessageService';
import { EventsList } from 'core/lists/EventsList';
import ModuleEventSubscriber from 'core/helpers/ModuleEventSubscriber';

const HomeMessages = (props: any) => {
  const folderType: IMessageFolderType = IMessageFolderType.Inbox;
  const module: any = flatModuleOptions<any>(props.module as any);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [conversations, setConversations] = useState<ConversationType[]>([]);
  const [conversation, setConversation] = useState<ConversationDetailsType>();
  const [orderedBy] = useState<number>(IConversationsOrderByType.DueDate);
  const [orderDirection] = useState<number>(IOrderByType.Descendent);
  const [currentPage] = useState<number>(emptyMessageState.currentPage);
  const [searchValue] = useState<string>(emptyMessageState.searchValue);

  const isInCaseContext = () =>
    module.DefaultFolder.toString().toLowerCase() ===
    IMessageFolderType[IMessageFolderType.Case].toString().toLowerCase();

  const fetchConversations = async () => {
    const requestConversations = {
      folderType,
      orderedBy,
      orderDirection,
      searchValue,
      moduleInstanceId: props.module.id,
      page: currentPage,
      caseNumber: isInCaseContext() ? (props.routeParameters.id as string) : '',
    };

    try {
      const getConversationsResult = await getConversations(
        requestConversations
      );
      setConversations(getConversationsResult.table.values);
    } catch (err) {
      InfoMessageService.error(
        TranslationService.translate('GeneralErrorOccured')
      );
    }
  };

  const fetchConversation = async (
    conversationId: number,
    creditorNumber: string
  ) => {
    setIsLoading(true);

    try {
      const getConversationResult = await getConversation(
        conversationId,
        creditorNumber
      );
      setConversation(getConversationResult);
    } catch (err) {
      InfoMessageService.error(
        TranslationService.translate('GeneralErrorOccured')
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleConversationChange = (
    conversationId: number,
    creditorNumber: string
  ) => {
    fetchConversation(conversationId, creditorNumber);
  };

  
  const loadData = async () =>{
    await fetchTilesCounters();
    await fetchConversations();
  };

  const handleMessageSubmit = async (msgValue: INewMessage) => {
    if (conversation) {
      setIsLoading(true);

      try {
        const requestData: INewMessageRequest = {
          message: msgValue,
          creditorNumber: conversation.caseDetails.creditor.number,
          conversationId: conversation.id,
        };

        const sendNewMessageResult = await sendNewMessage(
          requestData,
          props.module.id
        );

        if (sendNewMessageResult.status === Status.Success) {
          fetchConversation(
            conversation.id,
            conversation.caseDetails.creditor.number
          );
        } else {
          setIsLoading(false);
        }

        await loadData();

      } catch (err) {
        InfoMessageService.error(
          TranslationService.translate('GeneralErrorOccured')
        );
      }
    }
  };

  useEffect(() => {
    if (conversations && conversations.length) {
      fetchConversation(
        conversations[0].id,
        conversations[0].caseDetails.creditor.number
      );
    } else {
      setIsLoading(false);
    }
  }, [conversations]);

  useEffect(() => {
    const registerModuleEvents = () => {
      const moduleEvents = [
        { name: EventsList.CHANGED_CURRENT_CREDITOR, callback: loadData }
      ];
      ModuleEventSubscriber.registerEvents(moduleEvents);
    };

    registerModuleEvents();

    fetchConversations();
  }, []);

  return (
    <Card>
      <CardHeader>
        <h1 className="c-heading">
          {TranslationService.translateModule(
            'HomeMessagesLabel',
            ModuleNamesList.HomeMessages
          )}
        </h1>
      </CardHeader>

      <CardContent variant={CardContentVariants.Secondary}>
        <div
          className={classNames(
            styles.wrapper,
            conversations.length === 0 && styles.noCoversations
          )}
        >
          {isLoading && <Loader opacity={0.5} />}

          {conversations.length ? (
            conversation && (
              <div className="row">
                <div className="col-12 col-lg-4">
                  <ConversationList
                    conversations={conversations}
                    selectedConversationId={conversation.id}
                    onChange={handleConversationChange}
                  />
                </div>

                <div className="col-12 col-lg-8">
                  <ConversationMsgs
                    messages={conversation.messages}
                    onSubmit={handleMessageSubmit}
                  />
                </div>
              </div>
            )
          ) : (
            <p className={styles.notification}>
              {TranslationService.translateModule(
                'NoConversationInfo',
                ModuleNamesList.HomeMessages
              )}
            </p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, AnyAction>) => ({
  fetchTilesCounters: () => dispatch(fetchTilesCounters())
})

export default connect(null, mapDispatchToProps)(HomeMessages);
