import MessagesApiClient from './MessagesApiClient';
import { IConversationsTableResult } from './types/IConversationsTableResult';
import { IConversation } from './types/IConversation';
import { INewMessageRequest } from './types/INewMessageRequest';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import { ISearchConversationsRequest } from './types/ISearchConversationsRequest';
import { INewConversationRequest } from './types/INewConversationRequest';
import { ICreditor } from './types/ICreditor';

class MessagesService {
  public async getConversations(
    request: ISearchConversationsRequest
  ): Promise<IConversationsTableResult> {
    return await MessagesApiClient.getConversationsList(request);
  }

  public async getConversation(
    conversationId: number,
    creditorNumber: string
  ): Promise<IConversation> {
    return await MessagesApiClient.getConversation(conversationId, creditorNumber);
  }

  public async sendNewMessage(
    newMessageRequest: INewMessageRequest,
    moduleInstanceId: number
  ): Promise<IActionStatus> {
    const formData = new FormData();
    newMessageRequest.message.attachedFiles.forEach((element, index) => {
      formData.append('file', element, element.name);
    });
    if (newMessageRequest.conversationId) {
      formData.append('conversationId', newMessageRequest.conversationId.toString());
    }
    if (newMessageRequest.caseNumber) {
      formData.append('caseNumber', newMessageRequest.caseNumber);
    }
    formData.append('moduleInstanceId', moduleInstanceId.toString());
    formData.append('creditorNumber', newMessageRequest.creditorNumber);
    const message = newMessageRequest.message;
    message.attachedFiles = [];
    formData.append('message', JSON.stringify(message));

    return await MessagesApiClient.sendNewMessage(formData);
  }

  public async createNewConversation(
    newConversationRequest: INewConversationRequest,
    moduleInstanceId: number
  ): Promise<IActionStatus> {
    const formData = new FormData();
    newConversationRequest.message.attachedFiles.forEach((element) => {
      formData.append('file', element, element.name);
    });

    if (newConversationRequest.caseNumber) {
      formData.append('caseNumber', newConversationRequest.caseNumber);
    }
    formData.append('moduleInstanceId', moduleInstanceId.toString());
    formData.append('creditorNumber', newConversationRequest.creditorNumber);
    const message = newConversationRequest.message;
    message.attachedFiles = [];
    formData.append('message', JSON.stringify(message));

    return await MessagesApiClient.createNewConversation(formData);
  }

  public async getCreditorData(caseId: string): Promise<ICreditor> {
    return await MessagesApiClient.getCreditorData(caseId);
  }
}

export default new MessagesService();
