import RestApiClient from '../../core/api/RestApiClient';
import { IConversationsTableResult } from './types/IConversationsTableResult';
import { IConversation } from './types/IConversation';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import { ISearchConversationsRequest } from './types/ISearchConversationsRequest';
import { ICreditor } from './types/ICreditor';

class MessagesApiClient {
  public async getConversationsList(
    request: ISearchConversationsRequest
  ): Promise<IConversationsTableResult> {
    return await RestApiClient.post<IConversationsTableResult>(
      `modules/MessagesModule/getConversations`,
      request
    );
  }

  public async getConversation(
    conversationId: number,
    creditorNumber: string
  ): Promise<IConversation> {
    return await RestApiClient.get<IConversation>(
      `modules/MessagesModule/getConversation/${conversationId}/${creditorNumber}`
    );
  }

  public async sendNewMessage(formData: FormData): Promise<IActionStatus> {
    const headers = {
      'Content-Type': 'multipart/form-data;',
    };
    return await RestApiClient.post<IActionStatus>(
      `modules/MessagesModule/sendNewMessage`,
      formData,
      headers,
      false
    );
  }

  public async createNewConversation(formData: FormData): Promise<IActionStatus> {
    const headers = {
      'Content-Type': 'multipart/form-data;',
    };
    return await RestApiClient.post<IActionStatus>(
      `modules/MessagesModule/createNewConversation`,
      formData,
      headers,
      false
    );
  }

  public async getCreditorData(caseId: string): Promise<ICreditor> {
    return await RestApiClient.get<ICreditor>(`modules/MessagesModule/getCreditorData`, {
      caseId,
    });
  }
}

export default new MessagesApiClient();
