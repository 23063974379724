import React from 'react';
import './_CreateNewCase.scss';
import { CaseType } from './types/CaseType';
import { connect } from 'react-redux';
import { CreateNewCaseSteps } from './types/CreateNewCaseSteps';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import { ICaseNewMessage, emptyNewCaseMessage } from './types/ICaseNewMessage';
import { IClaimInfo, emptyClaimInfo } from './components/IClaimInfo';
import { IDebtorDetails, emptyDebtorDetails } from './types/IDebtorDetails';
import { IInitialSettings } from './types/IInitialSettings';
import { IInvoiceDetails } from './types/IInvoiceDetails';
import { IModuleProps } from '../../core/types/IModuleProps';
import { INumberDictionary } from '../../core/types/IDictionary';
import { IStepListItem } from '../StepListWizard/types/IStepListItem';
import { IStore } from '../../reducers/IStore';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import { Status } from '../../core/api/Enums/Status';
import { StepIconsCss } from '../StepListWizard/types/StepIconCss';
import StepList from '../StepListWizard/StepList';
import CaseInfo from './components/CaseInfo';
import CreateNewCaseApiClient from './api/CreateNewCaseApiClient';
import CreateNewCaseService from './CreateNewCaseService';
import DebtorInfo from './components/DebtorInfo';
import Done from './components/Done';
import InfoMessageService from '../../core/services/InfoMessageService';
import Loader from '../../core/components/Loading/Loader';
import Message from './components/Message';
import NewClaim from './components/NewClaim';
import TranslationService from '../../core/services/TranslationService';
import { CardContent } from '../../core/components/Card/components/CardContent';
import { Card } from '../../core/components/Card/Card';
import { CardHeader } from '../../core/components/Card/components/CardHeader';

interface IState {
  caseNumber: string;
  claimInfo: IClaimInfo;
  currentStep: CreateNewCaseSteps;
  currentStepIndex: number;
  debtorDetails: IDebtorDetails;
  instanceSettings: IInitialSettings;
  invoicesList: IInvoiceDetails[];
  isLoading: boolean;
  visitedSteps: number[];
  message: ICaseNewMessage;
}

interface IProps extends IModuleProps {
  locale: string;
}

const emptyState: IState = {
  caseNumber: '',
  claimInfo: { ...emptyClaimInfo },
  currentStep: CreateNewCaseSteps.ClaimInfo,
  currentStepIndex: 0,
  debtorDetails: { ...emptyDebtorDetails },
  instanceSettings: {} as IInitialSettings,
  invoicesList: [],
  isLoading: true,
  visitedSteps: [],
  message: { ...emptyNewCaseMessage },
};

class CreateNewCase extends React.Component<IProps, IState> {
  public stepList: INumberDictionary<IStepListItem>;
  public state: IState = { ...emptyState };

  constructor(props: IProps) {
    super(props);
    this.stepList = this.getStepComponentsList();
  }

  componentDidMount = async () => {
    await this.loadInstanceSettings();
  };

  loadInstanceSettings = async () => {
    this.setState({
      ...this.setState,
      isLoading: true,
    });
    const instanceSettings = await CreateNewCaseApiClient.getInitialSettings(this.props.module.id);
    this.setState({
      ...this.setState,
      instanceSettings,
      isLoading: false,
      claimInfo: {
        ...this.state.claimInfo,
        caseType: instanceSettings.availableCaseProductTypes[0].caseType,
      },
      debtorDetails: {
        ...this.state.debtorDetails,
        country: instanceSettings.debtorSetup.defaultCountryCode,
      },
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <article className={`l-module ${isLoading ? 'l-module--loading' : ''}`}>
        <section className="l-module__section l-module__section--head">
          <h1>
            <i className="fas fa-folder-plus mr-2" />
            {TranslationService.translateModule('HeaderCase', ModuleNamesList.CreateNewCase)}:
            <strong className="l-module__title-highlighted">
              {TranslationService.translateModule(
                'HeaderCreateNewCase',
                ModuleNamesList.CreateNewCase
              )}
            </strong>
          </h1>
        </section>

        <section className="l-module__section mt-3">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="animated fadeIn">
              <Card>
                <>
                  <CardHeader>
                    <StepList
                      stepList={this.stepList}
                      currentStep={this.state.currentStep}
                      leftDesktop={true}
                    />
                  </CardHeader>
                  <CardContent>
                    <div className="row">
                      <div className="col-12 col-md-10">{this.getStepComponent()}</div>
                    </div>
                  </CardContent>
                </>
              </Card>
            </div>
          )}
        </section>
      </article>
    );
  }

  private getStepComponentsList = (): INumberDictionary<IStepListItem> => {
    const stepComponents: INumberDictionary<IStepListItem> = {
      [CreateNewCaseSteps.ClaimInfo]: {
        iconCss: StepIconsCss.NewFile,
        text: TranslationService.translateModule(
          'StepHeaderClaimInfo',
          ModuleNamesList.CreateNewCase
        ),
      },
      [CreateNewCaseSteps.DebtorInfo]: {
        iconCss: StepIconsCss.Profile,
        text: TranslationService.translateModule(
          'StepHeaderDebtorInfo',
          ModuleNamesList.CreateNewCase
        ),
      },
      [CreateNewCaseSteps.CaseInfo]: {
        iconCss: StepIconsCss.Case,
        text: TranslationService.translateModule(
          'StepHeaderCaseInfo',
          ModuleNamesList.CreateNewCase
        ),
      },
      [CreateNewCaseSteps.Messages]: {
        iconCss: StepIconsCss.Message,
        text: TranslationService.translateModule(
          'StepHeaderMessages',
          ModuleNamesList.CreateNewCase
        ),
      },
      [CreateNewCaseSteps.Done]: {
        iconCss: StepIconsCss.Complete,
        text: TranslationService.translateModule('StepHeaderDone', ModuleNamesList.CreateNewCase),
      },
    };

    return stepComponents;
  };

  private getStepComponent = () => {
    switch (this.state.currentStep) {
      case CreateNewCaseSteps.ClaimInfo:
        return (
          <NewClaim
            goToNextStep={this.goToNextStep}
            claimInfo={this.state.claimInfo}
            setClaimInfo={this.setClaimInfo}
            isVisited={this.state.visitedSteps.includes(0)}
            moduleSettings={this.state.instanceSettings}
          />
        );
      case CreateNewCaseSteps.DebtorInfo:
        return (
          <DebtorInfo
            creditor={this.state.claimInfo.selectedCreditor}
            setDebtorDetails={this.setDebtorDetails}
            debtorDetails={this.state.debtorDetails}
            debtorSetup={this.state.instanceSettings.debtorSetup}
            backToPrevStep={this.backToPrevStep}
            goToNextStep={this.goToNextStep}
            moduleInstanceId={this.props.module.id}
            fieldToggles={{
              disableFirstName: this.state.instanceSettings.disableDebtorFirstName,
              disableHouseNo: this.state.instanceSettings.disableDebtorHouseNo,
              disableHouseNo2: this.state.instanceSettings.disableDebtorHouseNo2,
              disableReferenceNo: this.state.instanceSettings.disableReferenceNo,
            }}
          />
        );
      case CreateNewCaseSteps.CaseInfo:
        return (
          <CaseInfo
            claimInfo={this.state.claimInfo}
            instanceSettings={this.state.instanceSettings}
            invoicesSetup={this.state.instanceSettings.invoicesSetup}
            debtorDetails={this.state.debtorDetails}
            addNewInvoice={this.addInvoiceToList}
            updateInvoice={this.updateInvoice}
            removeInvoice={this.removeInvoice}
            attachFiles={this.attachFiles}
            invoicesList={this.state.invoicesList}
            backToPrevStep={this.backToPrevStep}
            goToNextStep={this.goToNextStep}
            caseType={this.state.claimInfo.caseType}
            setCaseType={this.setCaseType}
          />
        );
      case CreateNewCaseSteps.Messages:
        return (
          <Message
            goToNextStep={this.goToNextStep}
            backToPrevStep={this.backToPrevStep}
            setMessage={this.setMessage}
            message={this.state.message}
          />
        );
      case CreateNewCaseSteps.Done:
        return (
          <Done startFromBeginning={this.startFromBeginning} caseNumber={this.state.caseNumber} />
        );
    }
  };

  private startFromBeginning = () => {
    this.goToStep(0);
    this.setState({
      ...emptyState,
      instanceSettings: this.state.instanceSettings,
      invoicesList: [],
      isLoading: false,
    });
  };

  private goToStep = async (stepIndex: number) => {
    const nextStep = parseInt(Object.keys(this.stepList)[stepIndex], 10) as CreateNewCaseSteps;
    let goToNextStep: boolean = true;

    if (nextStep === CreateNewCaseSteps.Done) {
      await this.registerNewCase();
    }

    if (goToNextStep) {
      this.setState({
        ...this.state,
        currentStep: nextStep,
        currentStepIndex: stepIndex,
        visitedSteps: this.state.visitedSteps.includes(stepIndex)
          ? this.state.visitedSteps
          : [...this.state.visitedSteps, stepIndex],
      });
    }
  };

  private registerNewCase = async () => {
    this.setState({
      ...this.state,
      isLoading: true,
    });

    const invoicesList = [...this.state.invoicesList].map((invoicesList) => {
      const interest = this.state.instanceSettings.availableInterestRateTypes.find(
        (interest) => interest.isStandard && interest.interestId === invoicesList.interestTypeId
      );

      if (interest) {
        return {
          ...invoicesList,
          interest: this.state.instanceSettings.invoicesSetup.standardInvoiceRate,
        };
      }

      return invoicesList;
    });

    try {
      const actionStatus: IActionStatus = await CreateNewCaseService.createNewCase(
        this.state.debtorDetails,
        this.state.claimInfo,
        invoicesList,
        this.state.message,
        this.props.module.id
      );
      if (actionStatus.status !== Status.Success) {
        this.setState({
          ...this.state,
          isLoading: false,
          caseNumber: actionStatus.data,
        });
        InfoMessageService.displayActionStatus(actionStatus, true);
        return false;
      } else {
        this.setState({
          ...this.state,
          isLoading: false,
          caseNumber: actionStatus.data,
        });
        return true;
      }
    } catch {
      this.setState({
        ...this.state,
        isLoading: false,
      });
      InfoMessageService.error('Unknown error');
      return false;
    }
  };

  private goToNextStep = async () => {
    const nextStepIndex = this.state.currentStepIndex + 1;
    await this.goToStep(nextStepIndex);
  };

  private backToPrevStep = async () => {
    const nextStepIndex = this.state.currentStepIndex - 1;
    await this.goToStep(nextStepIndex);
  };

  private setDebtorDetails = (debtorDetails: IDebtorDetails) => {
    this.setState({
      debtorDetails,
    });
  };

  private setClaimInfo = (claimInfo: IClaimInfo) => {
    const caseType = claimInfo.caseType; //  === CollectionNotice.No && !this.state.instanceSettings.allowOnlyCollectionCases ? CaseType.CollectionNotice : CaseType.CollectionCase;
    this.setState({
      claimInfo: {
        ...claimInfo,
        caseType,
      },
      invoicesList: [],
    });
  };

  private setCaseType = (caseType: CaseType) => {
    this.setState({
      claimInfo: {
        ...this.state.claimInfo,
        caseType,
      },
    });
  };

  private setMessage = (message: ICaseNewMessage) => {
    this.setState({
      message,
    });
  };

  private addInvoiceToList = (invoice: IInvoiceDetails) => {
    const invoicesList = this.state.invoicesList;
    invoicesList.push(invoice);
    this.setState({
      invoicesList,
    });
  };

  private updateInvoice = (index: number, invoice: IInvoiceDetails) => {
    this.setState({
      invoicesList: [
        ...this.state.invoicesList.slice(0, index),
        { ...invoice },
        ...this.state.invoicesList.slice(index + 1),
      ],
    });
  };

  private attachFiles = (files: FileList, index: number) => {
    let selectedFiles = Array.from(files);
    const invoicesList = [...this.state.invoicesList];

    const numOfFiles = invoicesList.reduce(
      (sum, invoiceDetails) => sum + invoiceDetails.attachedFiles.length,
      0
    );

    if (numOfFiles + selectedFiles.length > this.state.instanceSettings.numOfCaseFiles) {
      InfoMessageService.error(
        TranslationService.translateModule('NumOfCaseFilesExceeded', ModuleNamesList.CreateNewCase)
      );
      const position = this.state.instanceSettings.numOfCaseFiles - numOfFiles;
      selectedFiles.splice(position);
    }

    const sizeOfAlreadyAddedFiles = invoicesList.reduce(
      (sum, invoiceDetails) =>
        sum + invoiceDetails.attachedFiles.reduce((sizeSum, file) => sizeSum + file.size, 0),
      0
    );
    const sizeOfNewFiles = selectedFiles.reduce((sizeSum, file) => sizeSum + file.size, 0);
    const limitMb = this.state.instanceSettings.maxSumFilesSizeMB * 1000000;

    if (sizeOfAlreadyAddedFiles + sizeOfNewFiles > limitMb) {
      InfoMessageService.error(
        TranslationService.translateModule('SizeOfCaseFilesExceeded', ModuleNamesList.CreateNewCase)
      );
      selectedFiles = [];
    }

    const invoice = { ...this.state.invoicesList[index] };
    invoice.attachedFiles = invoice.attachedFiles.concat(selectedFiles);
    invoicesList[index] = invoice;
    this.setState({
      invoicesList,
    });
  };

  private removeInvoice = (index: number) => {
    const invoicesList = this.state.invoicesList;
    invoicesList.splice(index, 1);
    this.setState({
      invoicesList,
    });
  };
}

const mapStateToProps = (state: IStore) => {
  return {
    locale: state.currentCultureCode,
    currency: state.currency,
  };
};

export default connect(mapStateToProps)(CreateNewCase);
